import { Box, Grid, Link, makeStyles, Typography } from "@material-ui/core"
import React from "react"
import {
  CONTAINER_PADDING,
  PINK_LIGHT_BG,
} from "../gatsby-theme-material-ui-top-layout/theme"
import { useMobile } from "../utils/hooks"
import TransparentRouterLink from "./common/TransparentRouterLink"
import SectionContainer from "./layout/SectionContainer"
import Logo from "./simple/Logo"

const useStyles = makeStyles(theme => ({
  paragraph: {
    maxWidth: 400,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  smallerLink: {
    fontSize: "inherit",
    textTransform: "uppercase",
  },
  email: {
    textDecoration: "underline",
  },
}))

const Footer = ({}) => {
  const classes = useStyles()
  const mobile = useMobile()
  return (
    <SectionContainer
      bgcolor={PINK_LIGHT_BG}
      py={CONTAINER_PADDING}
      flexDirection="column"
    >
      <Logo />

      <Typography className={classes.paragraph}>
        TopTof Fashion to platforma typu Crowdfunding RBF, umożliwiająca
        zbieranie funduszy w ramach finansowania społecznościowego, której celem
        jest organizowanie sieci inwestorów prywatnych jako początkowego
        zaplecza finansowego projektów z branży Fashion and Beauty.
      </Typography>

      <Grid container alignItems="flex-end" spacing={2}>
        <Grid item xs={12} md="auto">
          <Link to="" className={classes.smallerLink}>
            Regulamin
          </Link>
        </Grid>
        <Grid item xs={12} md="auto">
          <Link to="" className={classes.smallerLink}>
            Polityka prywatności
          </Link>
        </Grid>
        <Grid item xs={12} md="auto" style={{ marginLeft: "auto" }}>
          <Link
            href="mailto:hello@toptof.com"
            component="a"
            variant="h4"
            className={classes.email}
          >
            hello@toptof.com
          </Link>
        </Grid>
      </Grid>
    </SectionContainer>
  )
}

export default Footer
