import React from "react"
import src from "../../assets/svg/icons/checkroom.svg"

const CheckroomIcon = ({
  color = "#000",
  bgcolor,
  height = 30,
  width = 30,
}) => (
  <img
    src={src}
    style={{ background: bgcolor, fill: color }}
    height={height}
    width={width}
  />
)

export default CheckroomIcon
