import React from "react"
import { Typography } from "@material-ui/core"

export const mdRenderers = {
  heading: ({ children, level }) => (
    <Typography variant={"h" + level} children={children} />
  ),
  paragraph: ({ children }) => <Typography children={children} paragraph />,
}

export const hiddenOnMobile = {
  smDown: true,
}

export const hiddenOnDesktop = {
  mdUp: true,
}
