import { useMediaQuery } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"

export const CurrencyContext = React.createContext()

export const useCurrencies = code => {
  const currencyValue = React.useMemo(
    () =>
      ({
        PLN: { code: "PLN", label: "zł" },
        EUR: { code: "EUR", label: "€" },
      }[code] || { code: "XXX", label: "Brak" }),
    []
  )

  return currencyValue
}

export const mobile = t => t.breakpoints.down("sm")

export const useMobile = () => {
  const result = useMediaQuery(mobile)
  return result
}

export const useTags = () => {
  const {
    allTags: { nodes: allTags },
  } = useStaticQuery(graphql`
    query {
      allTags: allMarkdownRemark(
        filter: { fields: { collection: { eq: "tags" } } }
      ) {
        nodes {
          id
          frontmatter {
            name
            label
            image
            icon
          }
        }
      }
    }
  `)

  const getTagInfo = React.useCallback(tag => {
    return allTags.find(x => x.frontmatter.name === tag)
  }, [])

  return [getTagInfo, allTags]
}
