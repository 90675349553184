import { Box } from "@material-ui/core"
import React from "react"
import ContainerFlex from "../common/ContainerFlex"

const SectionContainer = ({
  id,
  children,
  bgcolor,
  component,
  maxWidth,
  p,
  px,
  py,
  pl,
  pr,
  pt,
  pb,
  className,
  outerClassName,
  ...props
}) => {
  const passedToOuterWrapper = Object.entries({
    id,
    bgcolor,
    component,
    className: outerClassName,
    p,
    px,
    py,
    pl,
    pr,
    pt,
    pb,
  }).reduce((a, [k, v]) => (v === undefined ? a : ((a[k] = v), a)), {})

  return (
    <Box position="relative" width="100%" {...passedToOuterWrapper}>
      <ContainerFlex maxWidth={maxWidth}>
        <Box display="flex" flexGrow={1} {...props}>
          {children}
        </Box>
      </ContainerFlex>
    </Box>
  )
}

export default SectionContainer
