import {
  Box,
  Button,
  ButtonBase,
  CardActionArea,
  Grid,
  Hidden,
  IconButton,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core"
import React from "react"
import { Person } from "@material-ui/icons"
import Logo from "./simple/Logo"
import TransparentRouterLink from "./common/TransparentRouterLink"
import clsx from "clsx"
import { MOBILE_X_PADDING } from "../gatsby-theme-material-ui-top-layout/theme"
import { useMobile } from "../utils/hooks"
import CheckroomIcon from "./icons/CheckroomIcon"
import { hiddenOnDesktop, hiddenOnMobile } from "../utils/constans"

const useStyles = makeStyles(theme => ({
  root: {
    position: "fixed",
    width: "100%",
    boxShadow: "0px -5px 50px rgba(0, 0, 0, 0.05)",
    justifyContent: "space-between",
  },
  rootHasLeftSlot: {},
  logoCentered: {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
  },
}))

const Header = ({ slotLeft, slotRight, maxHeight }) => {
  const classes = useStyles()
  const mobile = useMobile()

  return (
    <Box
      display="flex"
      bgcolor="background.paper"
      px={mobile ? 2 : 4}
      py={2}
      alignItems="center"
      maxHeight={maxHeight}
      className={clsx(classes.root, {
        [classes.rootHasLeftSlot]: !!slotLeft,
      })}
      zIndex={100}
      position={"relative"}
    >
      {slotLeft}

      <Logo
        mini={mobile}
        component={TransparentRouterLink}
        to="/"
        className={clsx({
          [classes.logoCentered]: !!slotLeft,
        })}
      />

      <Box display="flex" ml="auto" mr={0}>
        <Grid container spacing={mobile ? 1 : 6} alignItems="center">
          {slotRight}
          {/* <Grid item>
                        <Link to="/katalog">Katalog</Link>
                    </Grid>
                    <Grid item>
                        <Link to="/dla-projektanta">Dla projektanta</Link>
                    </Grid>
                    <Grid item>
                        <Link to="/dla-wspierajacego">Dla wspierającego</Link>
                    </Grid> */}

          <Grid item>
            <Hidden implementation="css" {...hiddenOnMobile}>
              <Box
                display="flex"
                bgcolor="#FAFAFA"
                borderRadius="40px"
                overflow="hidden"
              >
                <ButtonBase component={TransparentRouterLink} to={"/katalog"}>
                  <Box display="flex" alignItems="center" py={2} px={3}>
                    <CheckroomIcon />
                    <Box ml={1} />
                    <Typography variant="h6" noWrap className="underline">
                      Katalog projektów
                    </Typography>
                  </Box>
                </ButtonBase>

                <ButtonBase component={TransparentRouterLink} to={"/logowanie"}>
                  <Box display="flex" alignItems="center" py={2} px={3}>
                    <Person />
                    <Box ml={1} />
                    <Typography variant="h6" noWrap className="underline">
                      Konto
                    </Typography>{" "}
                  </Box>
                </ButtonBase>
              </Box>
            </Hidden>
            <Hidden implementation="css" {...hiddenOnDesktop}>
              <Box display="flex">
                <IconButton component={TransparentRouterLink} to={"/katalog"}>
                  <CheckroomIcon />
                </IconButton>
                <Box ml={1} />
                <IconButton component={TransparentRouterLink} to={"/logowanie"}>
                  <Person />
                </IconButton>
              </Box>
            </Hidden>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default Header
