import { Box } from "@material-ui/core"
import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import Footer from "../Footer"
import Header from "../Header"
import Seo from "../seo"

const HEADER_HEIGHT = 80

const Layout = ({ children, navbarLeftSlot, navbarRightSlot }) => {
  //   const data = useStaticQuery(graphql`
  //     query SiteTitleQuery {
  //       site {
  //         siteMetadata {
  //           title
  //         }
  //       }
  //     }
  // `)

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Seo />

      <Header
        slotLeft={navbarLeftSlot}
        slotRight={navbarRightSlot}
        maxHeight={HEADER_HEIGHT}
      />

      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        overflow="hidden"
        paddingTop={`${HEADER_HEIGHT}px`}
      >
        {children}
      </Box>

      <Footer />
    </Box>
  )
}

export default Layout
