import { Box } from "@material-ui/core"
import React from "react"
import logo from "../../assets/logo.png"
import logoMini from "../../assets/svg/icons/logo-mini.svg"

import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
const useStyles = makeStyles(theme => ({
  rootMini: {
    height: 42,
    width: 42,
  },
}))

const Logo = ({ mini, ...props }) => {
  const classes = useStyles()

  return (
    <Box {...props}>
      <img
        src={mini ? logoMini : logo}
        alt="Logo"
        className={clsx({
          [classes.rootMini]: !!mini,
        })}
      />
    </Box>
  )
}

export default Logo
