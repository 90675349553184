import React from "react"
import { Link as RouterLink } from "gatsby"
import { makeStyles } from "@material-ui/core"
import clsx from "clsx"

const useStyles = makeStyles(theme => ({
  root: {
    textDecoration: "unset",
    color: props => (props.buttonLink ? undefined : "inherit"),
    cursor: "pointer",
  },
}))

const externalLeads = ["http://", "https://", "mailto:"]

const isExternal = url => {
  return externalLeads?.some(x => url.includes(x))
}

const TransparentRouterLink = React.forwardRef(
  ({ className, buttonLink, ...props }, ref) => {
    const classes = useStyles({ buttonLink })

    return isExternal(props.to) ? (
      <a
        ref={ref}
        href={props.to}
        className={clsx(classes.root, className)}
        {...props}
      />
    ) : (
      <RouterLink
        ref={ref}
        className={clsx(classes.root, className)}
        {...props}
      />
    )
  }
)

export default TransparentRouterLink
