import { Container } from "@material-ui/core"
import React from "react"

const ContainerFlex = props => {
  return (
    <Container
      style={{ display: "flex", maxWidth: props.maxWidth }}
      {...props}
    />
  )
}

export default ContainerFlex
